export enum Permissions {
  VIEW_GENAI_FEEDBACKS = 'view-genai-feedbacks',
  LOGIN_AS = 'login-as',
  EDIT_USER_EMAIL = 'edit-user-email',
  EDIT_ORG_SETTINGS = 'edit-org-settings',
  DELETE_ORG = 'delete-org'
}

enum Groups {
  EMPLOYEE = 'employee',
  MANAGER = 'manager',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super-admin'
}

const permissions: { [key: string]: string[] } = {
  [Permissions.LOGIN_AS]: [Groups.ADMIN, Groups.MANAGER, Groups.SUPER_ADMIN],
  [Permissions.EDIT_ORG_SETTINGS]: [Groups.ADMIN, Groups.SUPER_ADMIN],
  [Permissions.DELETE_ORG]: [Groups.SUPER_ADMIN],
  [Permissions.VIEW_GENAI_FEEDBACKS]: [Groups.SUPER_ADMIN],
  [Permissions.EDIT_USER_EMAIL]: [Groups.SUPER_ADMIN]
}

export const isPermitted = (permission: string, user: any) => {
  const userGroups: string[] =
    user.signInUserSession.accessToken.payload['cognito:groups'] ?? []

  return userGroups.some((group) => permissions[permission].includes(group))
}
