import { Role } from '@epilot/permissions/types'
import { Row } from '@tanstack/table-core'
import { LogInIcon, MousePointerClick } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoginAsUserDialog } from '@/components/login-as-user-dialog'
import { SheetJSONEditor } from '@/components/sheet-json-editor'
import { Badge } from '@/components/ui/badge'
import { DataTable } from '@/components/ui/data-table'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import { useJSONEditor } from '@/hooks/useJSONEditor'
import { useUserTable } from '@/hooks/useUserTable'
import { IUser } from '@/models'
import { useAuth } from '@/providers'
import { useOrganizationCleanup } from '@/providers/OrganizationCleanupContextProvider'
import { RoutePaths } from '@/routes/routes'
import { Permissions, isPermitted } from '@/utils/permission-manager'

type UserListProps = {
  organizationId?: string
  loginAsDisabled?: boolean
  loginAsUserDialogProps?: Omit<
    React.ComponentProps<typeof LoginAsUserDialog>,
    'onOpenChange' | 'open' | 'user'
  >
  tableProps?: Omit<
    React.ComponentProps<typeof DataTable<IUser, IUser>>,
    'data' | 'tableConfigName' | 'columns'
  >
}

const UserList = ({
  organizationId,
  tableProps,
  loginAsUserDialogProps,
  loginAsDisabled = false
}: UserListProps) => {
  const { columns, data, isLoading, isReadyExport, isRolesLoading } =
    useUserTable(organizationId)
  const { organizationsToCleanup, isLoading: isLoadingOrganizationsToCleanup } =
    useOrganizationCleanup()
  const { userData } = useAuth()
  const loginAsPermitted = isPermitted(Permissions.LOGIN_AS, userData)
  const navigate = useNavigate()
  const jsonEditorProps = useJSONEditor()
  const [loginAsDialogOpen, setLoginAsDialogOpen] = useState(false)
  const [loginAsUser, setLoginAsUser] = useState<
    | {
        email: string
        orgId: string
      }
    | undefined
  >()

  const shouldShowLoginAsButton = (org_id: string) =>
    loginAsPermitted &&
    !loginAsDisabled &&
    !isLoadingOrganizationsToCleanup &&
    !organizationsToCleanup?.some((org) => org.org_id === org_id)

  return (
    <>
      <DataTable
        columns={columns.map((column) => {
          if (column.id === 'roles') {
            return {
              ...column,
              cell: isRolesLoading
                ? ({ row: _row }: { row: Row<IUser> }) => (
                    <div className="h-4 bg-muted rounded-lg animate-pulse" />
                  )
                : ({ row }: { row: Row<IUser> }) => {
                    const roles = data[row.index].roles ?? []

                    return (
                      <div className="flex overflow-y-scroll h-full py-3">
                        {(roles as Role[])?.map((role) => (
                          <Badge
                            className="bg-secondary hover:bg-hover cursor-pointer mr-2 min-w-min whitespace-nowrap"
                            key={role?.id + row.index}
                            onClick={(e) => {
                              e.stopPropagation()
                              jsonEditorProps.setOriginalData(role)
                              jsonEditorProps.setOpen(true)
                            }}
                            variant={'outline'}
                          >
                            {role?.name}{' '}
                            <MousePointerClick className="w-4 h-4 ml-1" />
                          </Badge>
                        ))}
                      </div>
                    )
                  }
            }
          } else if (column.id === 'actions') {
            return {
              ...column,
              cell: ({ row }: { row: Row<IUser> }) => (
                <DataTableRowActions
                  items={
                    shouldShowLoginAsButton(row.original.organization_id!)
                      ? [
                          {
                            icon: <LogInIcon className="w-4 h-4 mr-2" />,
                            label: 'Login as',
                            disabled: row.original.status !== 'Active',
                            onClick: async () => {
                              const email = row.original.email

                              if (email) {
                                setLoginAsUser({
                                  email,
                                  orgId: row.original.organization_id!
                                })
                                setLoginAsDialogOpen(true)
                              }
                            }
                          }
                        ]
                      : []
                  }
                  key={column.id}
                  row={row}
                />
              )
            }
          }

          return column
        })}
        data={data}
        isLoading={isLoading}
        onRowClick={(row) => {
          navigate(
            `${RoutePaths.USERS}/${row.original.id}?orgId=${row.original.organization_id}`
          )
        }}
        tableConfigName="user"
        toolbarProps={{
          exportButtonProps: {
            loading: !isReadyExport
          }
        }}
        {...tableProps}
      />
      <SheetJSONEditor {...jsonEditorProps} />
      <LoginAsUserDialog
        onOpenChange={(open) => setLoginAsDialogOpen(open)}
        open={loginAsDialogOpen}
        user={loginAsUser!}
        {...loginAsUserDialogProps}
      />
    </>
  )
}

export default UserList
