import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getUserClient, UserPaths } from '@/api/user-client'
import { ErrorResponse, IUser } from '@/models'

const userClient = getUserClient()

const USER_QUERY_IDS = {
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  GET_USER_TAGS: 'GET_USER_TAGS',
  GET_USERS: 'GET_USERS',
  GET_ORGANIZATION_USER: 'GET_ORGANIZATION_USER',
  GET_USER_DATA_POINTS: 'GET_USER_DATA_POINTS'
}

export const useQueryGetOrgUsers: (
  orgId: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.listUsersV2>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.listUsersV2>>>
> = (orgId, opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.listUsersV2>>>({
    queryKey: [USER_QUERY_IDS.GET_ORGANIZATION_USER, orgId],
    queryFn: () =>
      userClient.listUsersV2(
        { limit: 1000 },
        {},
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    refetchOnWindowFocus: true,
    ...opts
  })
}

export const useQueryGetUserById: (
  userId: string,
  orgId: string,
  opts?: Partial<UseQueryOptions<IUser>>
) => ReturnType<typeof useQuery<IUser>> = (userId, orgId, opts) => {
  return useQuery<IUser>({
    queryKey: [USER_QUERY_IDS.GET_USER_BY_ID, userId, orgId],
    queryFn: () =>
      userClient
        .getUserV2({ id: userId }, {}, { headers: { 'x-ivy-org-id': orgId } })
        .then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetUserTags: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getUserTags>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.getUserTags>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.getUserTags>>>({
    queryKey: [USER_QUERY_IDS.GET_USER_TAGS],
    queryFn: () => userClient.getUserTags(),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetUsers: (
  opts?: Omit<
    UseQueryOptions<UserPaths.GetUsersV2.Responses.$200['results']> &
      UserPaths.GetUsersV2.QueryParameters,
    'queryKey' | 'queryFn'
  >
) => ReturnType<
  typeof useQuery<UserPaths.GetUsersV2.Responses.$200['results']>
> = (opts) => {
  const fetchAllUsers = async (orgId?: string) => {
    const limit = '1000'
    let users: UserPaths.GetUsersV2.Responses.$200['results'] = []
    // TODO: update type on API
    let lastEvaluatedKey: any = null

    do {
      const { data } = await fetchUsers(
        limit,
        JSON.stringify(lastEvaluatedKey),
        orgId
      )

      if (!data.results?.length) {
        break
      }

      users = users.concat(data.results)
      lastEvaluatedKey = data.last_evaluated_key
    } while (lastEvaluatedKey)

    return users
  }

  const fetchUsers = async (
    limit: string,
    startKey: string,
    orgId?: string
  ) => {
    return userClient.getUsersV2({
      limit,
      start_key: startKey,
      org_id: orgId
    })
  }

  return useQuery<UserPaths.GetUsersV2.Responses.$200['results']>({
    queryKey: [USER_QUERY_IDS.GET_USERS, opts?.org_id],
    queryFn: async () => fetchAllUsers(opts?.org_id),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetUserDataPoints: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getDataPoints>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.getDataPoints>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.getDataPoints>>>({
    queryKey: [USER_QUERY_IDS.GET_USER_DATA_POINTS],
    queryFn: () => userClient.getDataPoints(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useMutationUpdateUser: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof userClient.updateUserV2>>,
    ErrorResponse,
    IUser,
    unknown
  >
> = (orgId) => {
  return useMutation<
    Awaited<ReturnType<typeof userClient.updateUserV2>>,
    ErrorResponse,
    IUser,
    unknown
  >({
    mutationFn: (user: IUser) => {
      return userClient.updateUserV2(user.id, user, {
        headers: {
          'x-ivy-org-id': orgId
        }
      })
    }
  })
}

export const useMutationUpdateUserEmail: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof userClient.changeUserEmail>>,
    ErrorResponse,
    { oldEmail: string; newEmail: string },
    unknown
  >
> = (orgId) => {
  return useMutation<
    Awaited<ReturnType<typeof userClient.changeUserEmail>>,
    ErrorResponse,
    { oldEmail: string; newEmail: string },
    unknown
  >({
    mutationFn: ({
      oldEmail,
      newEmail
    }: {
      oldEmail: string
      newEmail: string
    }) => {
      return userClient.changeUserEmail(
        { username: oldEmail },
        { new_email: newEmail },
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      )
    }
  })
}
